import React, {useState, useEffect} from "react"
import tw, { css } from "twin.macro"

const AccordionIndex = ({
	items,

}) => {
	  const [isExpanded, setIsExpanded] = useState({});
      
    
      const toggleExpand = (index ) => {
        setIsExpanded(prevState => {
          const newState =  { [index]: !prevState[index] };
          return newState;
        });
      };

	const roundedStyle = css`
		border: 2px solid #2d3442;
	`

	return (
		<>
			<ul tw="flex flex-col px-1 space-y-2 md:space-y-4 w-full">
				{items.map((item, index) => (
					<li
						key={index}
						css={[
							tw`flex flex-col space-y-3 px-4 md:px-7 text-xs rounded-3xl`,
							roundedStyle,
							isExpanded[index] && 'bg-white',
							!isExpanded[index] && 'background-color: #2d3442;',
							css`
								transition: background-color 1s ease-in-out,
									color 1s ease-in-out;
							`,
						]}
					>
						<button
							aria-label={`${item.title} button`}
							css={tw`flex items-center font-bold pt-3 pb-0 md:pt-4 md:pb-1`}
							onClick={() => toggleExpand(index, Number(item.reviseIndex) + 1)}
						>
							<button
								css={[
									tw`p-2 rounded-full font-extrabold mr-4 text-xl cursor-pointer text-center transition duration-100 ease-in-out`,
									!isExpanded[index] && 'background-color: #FFFFFF;',
									isExpanded[index] && 'background-color: #5fc6da;',
									css`
										transition: background-color 1s ease-in-out,
											color 1s ease-in-out;
									`,
								]}
							>
								{isExpanded[index] ? (
									<svg
										xmlns="http://www.w3.org/2000/svg"
										fill="red"
										viewBox="0 0 24 24"
										stroke="white"
										css={tw`rounded-full h-5 w-5 cursor-pointer`}
									>
										<path
											strokeLinecap="round"
											strokeLinejoin="round"
											strokeWidth={3}
											d="M6 12h12"
										/>
									</svg>
								) : (
									<svg
										xmlns="http://www.w3.org/2000/svg"
										fill="none"
										viewBox="0 0 24 24"
										stroke="#58C8DF"
										css={tw`rounded-full h-5 w-5 cursor-pointer`}
									>
										<path
											strokeLinecap="round"
											strokeLinejoin="round"
											strokeWidth={3}
											d="M12 6v6m0 0v6m0-6h6m-6 0H6"
										/>
									</svg>
								)}
							</button>
							<span
								css={[
									tw`text-xs`,
									css`
										transition: background-color 1s ease-in-out,
											color 1s ease-in-out;
									`,
									css`
										color: ${isExpanded[index] ? '#0E0E0E' : '#5fc6da'};
										white-space: pre-line;
										text-align: left;
									`,
								]}
							>
								{item.title}
							</span>
						</button>
						<div
							style={{
								maxHeight: isExpanded[index] ? '1000px' : '0',
								transition: 'max-height 1s ease-in-out',
								overflow: 'hidden',
							}}
						>
							<p css={tw`pb-5 text-sm text-black font-normal`}>
								{item.content}
							</p>
						</div>
					</li>
				))}
			</ul>
		</>
	)
}

export default AccordionIndex